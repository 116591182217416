<template>
  <div>
    <profile-header text="Calendar" />

    <v-row>
      <v-col>
        <div class="header">
          {{ calendarHeader(new Date()) }}
        </div>
        <v-calendar 
          type="month" 
          :weekdays="[1,2,3,4,5,6,0]"
          :show-month-on-first="false"
          short-months 
          short-weekdays
        />
      </v-col>
      <v-col>
        <div class="header">
          {{ calendarHeader(nextMonth) }}
        </div>
        <v-calendar 
          type="month" 
          :start="nextMonth"
          :weekdays="[1,2,3,4,5,6,0]"
          :show-month-on-first="false"
          short-months 
          short-weekdays
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader.vue';

export default {
  components: {
    ProfileHeader
  },
  methods: {
    calendarHeader(date) {
      new Date().toLocaleString()
      return date.toLocaleString('en-EN', { month: 'long', year: 'numeric'});
    },
    paddedDate(value) {
      return ("00" + value).slice(-2);
    }
  },
  computed: {
    nextMonth() {
      const date = new Date(); 

      date.setMonth(date.getMonth() + 1);

      return date;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  text-align: center;

  padding: 1rem;

  font-size: 20px;
  font-weight: 300;
}
</style>